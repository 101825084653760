body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "TacTicsSans";
  src: local("Squares Thin Italic.otf"), url("/src/assets/fonts/TacticSans/TacticSans-LightItalic.otf") format("opentype");
  font-display: swap;
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "TacTicsSans";
  src: local("Squares Thin Italic.otf"), url("/src/assets/fonts/TacticSans/TacticSans-Light.otf") format("opentype");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: "TacTicsSans";
  src: local("Squares Regular.otf"), url("/src/assets/fonts/TacticSans/TacticSans-Regular.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "TacTicsSans";
  src: local("Squares Italic.otf"), url("/src/assets/fonts/TacticSans/TacticSans-RegularItalic.otf") format("opentype");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: "TacTicsSans";
  src: local("TacticSans Italic.otf"), url("/src/assets/fonts/TacticSans/TacticSans-Bold.otf") format("opentype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "TacTicsSans";
  src: local("Squares Black.otf"), url("/src/assets/fonts/TacticSans/TacticSans-BoldItalic.otf") format("opentype");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

